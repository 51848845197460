import React from 'react'
import site from "../tripsccreens/Img/site.png"
import ss from "./ss.png"
import sts from "./truck.png"
import sf from "./bnbg.png"
import rsp from "./rps.png"

function Pages() {
  return (
    <div className='text-center mb-10'>
      <div className='bg-black py-5 text-center justify-center mt-5 flex gap-5'>
        <p className='text-red-800 text-4xl font-bold'>Recent</p> <p className='text-white text-4xl font-bold'> projects</p>
      </div>

      <div className='lg:flex justify-center'>
        <div className='lg:w-[300px] mb-10 hover:scale-110 duration-700 p-2'>
          <a target='_blank' href="https://dentelo-master.vercel.app">
            <img className='lg:w-[300px] lg:h-[]' src={site} alt="" />
          </a>
        </div>
        <div className='lg:w-[300px] hover:scale-110 duration-700 mb-10 p-2'>
          <a target='_blank' href="https://dentelo-master.vercel.app">
             <img className='lg:w-[300px] lg:h-[]' src={ss} alt="" />
          </a>
        </div>
        <div className='lg:w-[300px] hover:scale-110 duration-700 mb-10 p-2'>
          <a target='_blank' href="https://transportio-master.vercel.app">
             <img className='lg:w-[500px] lg:h-[]' src={sts} alt="" />
          </a>
        </div>
        <div className='lg:w-[300px] hover:scale-110 duration-700 mb-10 p-2'>
          <a target='_blank' href="https://real-estate-website-eight-rouge.vercel.app/">
          <img className='lg:w-[500px] lg:h-[]' src={sf} alt="" />
          </a>
        </div>
        <div className='lg:w-[300px] hover:scale-110 duration-700 mb-10 p-2'>
          <a target='_blank' href="https://real-estate-website-eight-rouge.vercel.app/">
          <img className='lg:w-[500px] lg:h-[]' src={sf} alt="" />
          </a>
        </div>
      </div>


    {/* <div>
      <h3 className='text-center text-2xl p-2 ml-5 font-bold'>Web Development Services</h3>

      <div className='flex justify-evenly my-20'>
          <div className='w-96 h-96'>
            <img src={rsp} alt="" />
            <p>Responsive web design is swifly becoming the future of web development. It is the most efficient and 
              cost efective format to ensure your site provides a seemless user experience across all mobile
               devices, our team has the experience, tools and technology to create 100% accessible web sites that work.</p>
          </div>

          <div className='w-96 h-96'>
            <img src={rsp} alt="" />
            <p>Responsive web design is swifly becoming the future of web development. It is the most efficient and 
              cost efective format to ensure your site provides a seemless user experience across all mobile
               devices, our team has the experience, tools and technology to create 100% accessible web sites that work.</p>
          </div>

          <div className='w-96 h-96'>
            <img src={rsp} alt="" />
            <p>Responsive web design is swifly becoming the future of web development. It is the most efficient and 
              cost efective format to ensure your site provides a seemless user experience across all mobile
               devices, our team has the experience, tools and technology to create 100% accessible web sites that work.</p>
          </div>

      </div>
    </div> */}

    </div>
  )
}

export default Pages