import React from 'react';
// import ss from "./ss.png";
import { FaLink, FaPhone } from 'react-icons/fa6';
import mn from "./beauty1.png"
import { MdOutlineAttachEmail } from 'react-icons/md';
import mb from "./beauty2.png"
import bb from "./beautys.png"
import vv from "./Screenshot 2024-10-11 140114.png"
import vc from "./Screenshot 2024-10-11 140248.png"
import bv from "./Screenshot 2024-10-11 140937.png"



const Beauty = () => {
  const handleClick = (url) => {
    window.open(url, '_blank');  // Open the link in a new tab
  };

  return (
    <div className='text-center flex-1 justify-center'>
      <h2 className='text-center m-2 p-5 text-3xl font-bold'>WEBSITES</h2>

      <div className='grid lg:flex-cols-2 items-center self-center justify-center '>
      <h2 className='text-center m-2 p-5 text-3xl'>BEAUTY WEBSITES</h2>



        <div className='relative lg:w- lg:p-10 mb-10 text-center items-center hover:scale-105 duration-700'>
        <img className='lg:w-[800px] lg:h-[] transition-all duration-300 filter hover:blur-md' src={mn} alt="" />
     
        <div 
          className='absolute inset-0 flex items-center justify-center opacity-0 hover:opacity-100 transition-opacity duration-300'
         >
        <div className='flex gap-5'>
          <div className='bg-white p-3 flex gap-5 rounded-full shadow-lg cursor-pointer'>
            <FaLink  onClick={() => handleClick('https://perfume-website-main.vercel.app/')} color='black' />
          </div>
          <div className='bg-white p-3 flex gap-5 rounded-full shadow-lg cursor-pointer'>
          <a href="tel:800-556-1849" >
            <FaPhone color='black' />
            </a>
          </div>
          <div className='bg-white p-3 flex gap-5 rounded-full shadow-lg cursor-pointer'>
          <a href="mailto:info@lastminutedispatch.com" >
            <MdOutlineAttachEmail color='black' />
            </a>
          </div>
        </div>
    
        </div>
      </div>

      <div className='relative lg:w- lg:p-10 mb-10 text-center items-center hover:scale-105 duration-700'>
      <img className='lg:w-[800px] lg:h-[] transition-all duration-300 filter hover:blur-md' src={mb} alt="" />
   
      <div 
        className='absolute inset-0 flex items-center justify-center opacity-0 hover:opacity-100 transition-opacity duration-300'
       >
      <div className='flex gap-5'>
        <div className='bg-white p-3 flex gap-5 rounded-full shadow-lg cursor-pointer'>
          <FaLink  onClick={() => handleClick('https://glowing-master-eta.vercel.app/')} color='black' />
        </div>
        <div className='bg-white p-3 flex gap-5 rounded-full shadow-lg cursor-pointer'>
        <a href="tel:800-556-1849" >
          <FaPhone color='black' />
          </a>
        </div>
        <div className='bg-white p-3 flex gap-5 rounded-full shadow-lg cursor-pointer'>
        <a href="mailto:info@lastminutedispatch.com" >
          <MdOutlineAttachEmail color='black' />
          </a>
        </div>
      </div>

      </div>
    </div>




    <div className='relative lg:w- lg:p-10 mb-10 text-center items-center hover:scale-105 duration-700'>
    <img className='lg:w-[800px] lg:h-[] transition-all duration-300 filter hover:blur-md' src={bb} alt="" />
    <div 
      className='absolute inset-0 flex items-center justify-center opacity-0 hover:opacity-100 transition-opacity duration-300'
     >
    <div className='flex gap-5'>
      <div className='bg-white p-3 flex gap-5 rounded-full shadow-lg cursor-pointer'>
        <FaLink  onClick={() => handleClick('https://beauty-tau-roan.vercel.app/')} color='black' />
      </div>
      <div className='bg-white p-3 flex gap-5 rounded-full shadow-lg cursor-pointer'>
      <a href="tel:800-556-1849" >
        <FaPhone color='black' />
        </a>
      </div>
      <div className='bg-white p-3 flex gap-5 rounded-full shadow-lg cursor-pointer'>
      <a href="mailto:info@lastminutedispatch.com" >
        <MdOutlineAttachEmail color='black' />
        </a>
      </div>
    </div>

    </div>
  </div>
    


  
  <div className='relative lg:w- lg:p-10 mb-10 text-center items-center hover:scale-105 duration-700'>
  <img className='lg:w-[800px] lg:h-[] transition-all duration-300 filter hover:blur-md' src={vv} alt="" />
  <div 
    className='absolute inset-0 flex items-center justify-center opacity-0 hover:opacity-100 transition-opacity duration-300'
   >
  <div className='flex gap-5'>
    <div className='bg-white p-3 flex gap-5 rounded-full shadow-lg cursor-pointer'>
      <FaLink  onClick={() => handleClick('https://biagiotti.qodeinteractive.com/')} color='black' />
    </div>
    <div className='bg-white p-3 flex gap-5 rounded-full shadow-lg cursor-pointer'>
    <a href="tel:800-556-1849" >
      <FaPhone color='black' />
      </a>
    </div>
    <div className='bg-white p-3 flex gap-5 rounded-full shadow-lg cursor-pointer'>
    <a href="mailto:info@lastminutedispatch.com" >
      <MdOutlineAttachEmail color='black' />
      </a>
    </div>
  </div>

  </div>
</div>




<div className='relative lg:w- lg:p-10 mb-10 text-center items-center hover:scale-105 duration-700'>
<img className='lg:w-[800px] lg:h-[] transition-all duration-300 filter hover:blur-md' src={vc} alt="" />
<div 
  className='absolute inset-0 flex items-center justify-center opacity-0 hover:opacity-100 transition-opacity duration-300'
 >
<div className='flex gap-5'>
  <div className='bg-white p-3 flex gap-5 rounded-full shadow-lg cursor-pointer'>
    <FaLink  onClick={() => handleClick('https://preview.themeforest.net/item/spaville-spa-and-beauty-html-template/full_screen_preview/54551715?_ga=2.31286207.1200249808.1728621347-449956855.1728614946&_gac=1.156845513.1728651593.CjwKCAjwmaO4BhAhEiwA5p4YL-Ga9eLaxcop_KJjdkaVbQh_RuaWdFZffoxNAgnwhUCDASbqeQOQzhoCL1wQAvD_BwE')} color='black' />
  </div>
  <div className='bg-white p-3 flex gap-5 rounded-full shadow-lg cursor-pointer'>
  <a href="tel:800-556-1849" >
    <FaPhone color='black' />
    </a>
  </div>
  <div className='bg-white p-3 flex gap-5 rounded-full shadow-lg cursor-pointer'>
  <a href="mailto:info@lastminutedispatch.com" >
    <MdOutlineAttachEmail color='black' />
    </a>
  </div>
</div>

</div>
</div>
 


<div className='relative lg:w- lg:p-10 mb-10 text-center items-center hover:scale-105 duration-700'>
<img className='lg:w-[800px] lg:h-[] transition-all duration-300 filter hover:blur-md' src={bv} alt="" />
<div 
  className='absolute inset-0 flex items-center justify-center opacity-0 hover:opacity-100 transition-opacity duration-300'
 >
<div className='flex gap-5'>
  <div className='bg-white p-3 flex gap-5 rounded-full shadow-lg cursor-pointer'>
    <FaLink  onClick={() => handleClick('https://preview.themeforest.net/item/aonespa-beauty-spa-center-html-template/full_screen_preview/54744580?_ga=2.258312074.1200249808.1728621347-449956855.1728614946&_gac=1.149619396.1728651593.CjwKCAjwmaO4BhAhEiwA5p4YL-Ga9eLaxcop_KJjdkaVbQh_RuaWdFZffoxNAgnwhUCDASbqeQOQzhoCL1wQAvD_BwE')} color='black' />
  </div>
  <div className='bg-white p-3 flex gap-5 rounded-full shadow-lg cursor-pointer'>
  <a href="tel:800-556-1849" >
    <FaPhone color='black' />
    </a>
  </div>
  <div className='bg-white p-3 flex gap-5 rounded-full shadow-lg cursor-pointer'>
  <a href="mailto:info@lastminutedispatch.com" >
    <MdOutlineAttachEmail color='black' />
    </a>
  </div>
</div>

</div>
</div>
      </div>
    </div>
  );
};

export default Beauty;
