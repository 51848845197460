import React from 'react';
import ss from "./ss.png";
import st from "./img/NNScreenshot 2024-09-24 145424.png";
import rsp from "./img/ppScreenshot 2024-09-24 144318.png";
import { FaLink, FaPhone } from 'react-icons/fa6';
import mn from "./img/Screenshot 2024-09-24 092937.png"
import { MdOutlineAttachEmail } from 'react-icons/md';
import mb from "./img/Screenshot 2024-09-24 093347.png"


const Health = () => {
  const handleClick = (url) => {
    window.open(url, '_blank');  // Open the link in a new tab
  };

  return (
    <div className='text-center flex-1 justify-center'>
      <h2 className='text-center m-2 p-5 text-3xl font-bold'>WEBSITES</h2>

      <div className='grid lg:flex-cols-2 items-center self-center justify-center '>
      <h2 className='text-center m-2 p-5 text-3xl'>MEDICAL WEBSITES</h2>

        <div className='relative lg:w- lg:p-10 mb-10 hover:scale-105 duration-700'>
          <img className='lg:w-[800px] lg:h-[] transition-all duration-300 filter hover:blur-md' src={ss} alt="" />
          <div 
            className='absolute inset-0 flex items-center justify-center opacity-0 hover:opacity-100 transition-opacity duration-300'
          >
             <div className='flex gap-5'>
            <div className='bg-white p-3 flex gap-5 rounded-full shadow-lg cursor-pointer'>
              <FaLink  onClick={() => handleClick('https://dentelo-master.vercel.app/')} color='black' />
            </div>
            <div className='bg-white p-3 flex gap-5 rounded-full shadow-lg cursor-pointer'>
            <a href="tel:800-556-1849" >
              <FaPhone color='black' />
              </a>
            </div>
            <div className='bg-white p-3 flex gap-5 rounded-full shadow-lg cursor-pointer'>
            <a href="mailto:info@lastminutedispatch.com" >
              <MdOutlineAttachEmail color='black' />
              </a>
            </div>
          </div>
          </div>
        </div>

        <div className='relative lg:w- lg:p-10 mb-10 text-center items-center hover:scale-105 duration-700'>
        <img className='lg:w-[800px] lg:h-[] transition-all duration-300 filter hover:blur-md' src={mn} alt="" />
     
        <div 
          className='absolute inset-0 flex items-center justify-center opacity-0 hover:opacity-100 transition-opacity duration-300'
         >
        <div className='flex gap-5'>
          <div className='bg-white p-3 flex gap-5 rounded-full shadow-lg cursor-pointer'>
            <FaLink  onClick={() => handleClick('https://revo-hospital-website-html-css-js-master.vercel.app/')} color='black' />
          </div>
          <div className='bg-white p-3 flex gap-5 rounded-full shadow-lg cursor-pointer'>
          <a href="tel:800-556-1849" >
            <FaPhone color='black' />
            </a>
          </div>
          <div className='bg-white p-3 flex gap-5 rounded-full shadow-lg cursor-pointer'>
          <a href="mailto:info@lastminutedispatch.com" >
            <MdOutlineAttachEmail color='black' />
            </a>
          </div>
        </div>
    
        </div>
      </div>

      <div className='relative lg:w- lg:p-10 mb-10 text-center items-center hover:scale-105 duration-700'>
      <img className='lg:w-[800px] lg:h-[] transition-all duration-300 filter hover:blur-md' src={mb} alt="" />
   
      <div 
        className='absolute inset-0 flex items-center justify-center opacity-0 hover:opacity-100 transition-opacity duration-300'
       >
      <div className='flex gap-5'>
        <div className='bg-white p-3 flex gap-5 rounded-full shadow-lg cursor-pointer'>
          <FaLink  onClick={() => handleClick('https://health-care-website-eta.vercel.app/')} color='black' />
        </div>
        <div className='bg-white p-3 flex gap-5 rounded-full shadow-lg cursor-pointer'>
        <a href="tel:800-556-1849" >
          <FaPhone color='black' />
          </a>
        </div>
        <div className='bg-white p-3 flex gap-5 rounded-full shadow-lg cursor-pointer'>
        <a href="mailto:info@lastminutedispatch.com" >
          <MdOutlineAttachEmail color='black' />
          </a>
        </div>
      </div>

      </div>
    </div>


      <div className='relative lg:w- lg:p-10 mb-10 text-center items-center hover:scale-105 duration-700'>
      <img className='lg:w-[800px] lg:h-[] transition-all duration-300 filter hover:blur-md' src={rsp} alt="" />
   
      <div 
        className='absolute inset-0 flex items-center justify-center opacity-0 hover:opacity-100 transition-opacity duration-300'
       >
      <div className='flex gap-5'>
        <div className='bg-white p-3 flex gap-5 rounded-full shadow-lg cursor-pointer'>
          <FaLink  onClick={() => handleClick('https://graphiximage.com/samples/dentists-web-design/')} color='black' />
        </div>
        <div className='bg-white p-3 flex gap-5 rounded-full shadow-lg cursor-pointer'>
        <a href="tel:800-556-1849" >
          <FaPhone color='black' />
          </a>
        </div>
        <div className='bg-white p-3 flex gap-5 rounded-full shadow-lg cursor-pointer'>
        <a href="mailto:info@lastminutedispatch.com" >
          <MdOutlineAttachEmail color='black' />
          </a>
        </div>
      </div>

      </div>
    </div>

      <div className='relative lg:w- lg:p-10 mb-10 text-center items-center hover:scale-105 duration-700'>
      <img className='lg:w-[800px] lg:h-[] transition-all duration-300 filter hover:blur-md' src={st} alt="" />
   
      <div 
        className='absolute inset-0 flex items-center justify-center opacity-0 hover:opacity-100 transition-opacity duration-300'
       >
      <div className='flex gap-5'>
        <div className='bg-white p-3 flex gap-5 rounded-full shadow-lg cursor-pointer'>
          <FaLink  onClick={() => handleClick('https://graphiximage.com/samples/med-web-designs/')} color='black' />
        </div>
        <div className='bg-white p-3 flex gap-5 rounded-full shadow-lg cursor-pointer'>
        <a href="tel:800-556-1849" >
          <FaPhone color='black' />
          </a>
        </div>
        <div className='bg-white p-3 flex gap-5 rounded-full shadow-lg cursor-pointer'>
        <a href="mailto:info@lastminutedispatch.com" >
          <MdOutlineAttachEmail color='black' />
          </a>
        </div>
      </div>

      </div>
    </div>

    

 
      </div>
    </div>
  );
};

export default Health;
