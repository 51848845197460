import React from 'react'
import img from "./prize tag.png"
import r from "./6.jpg"
import m from "./5.jpg"
import q from "./2.jpg"
import { TiTickOutline } from 'react-icons/ti'
import { Select } from 'antd'

const Services = () => {
  return (
    <div>
        <div>
        <h2 className='text-center m-2 p-5 text-3xl font-bold'>ALL DIGITAL MARKETING SERVICES</h2>
        <div className='lg:flex justify-evenly my-10 p-10'>
            <div>
                <img className='w-96 p-5' src={r} alt="" />
            </div>
            <div>
                <img className='w-96 p-2' src={m} alt="" />
            </div>
            <div>
                <img className='w-96 p-5' src={q} alt="" />
            </div>
        </div>
        <div className='text-center mb-20'>
            <img className='w-96 inline' src={img} alt="" />
        </div>

        <div className='lg:flex mx-5 justify-evenly'>   

            <div>
                <p className='text-start text-xl py-5 font-bold'>FULLY CUSTOMDESIGNS</p>
                <div className='w-96 flex'>
                    {/* <TiTickOutline size={40}/> */}
                    <p>Graphix Image ensures the perfect solution, so that you and your business can thrive with all the opportunities 
                    for promotion and business on the Internet by providing you with an innovative professional web site.</p>
                </div>
            </div>
            <div>
                <p className='text-start text-xl py-5 font-bold'>LEADSGENERATORS</p>
                <div className='w-96 flex'>
                    {/* <TiTickOutline className=' w-96'/> */}
                    <p>Our affordable responsive leads generators are designed and optimized specifically to 
                        target competitive search terms/keywords that are 
                        hard to achieve organically otherwise, call us today for more info and details.</p>
                </div>
            </div>
            <div>
                <p className='text-start text-xl py-5 font-bold'>ADWORDSMARKETING</p>
                <div className='w-96 flex'>
                    {/* <TiTickOutline size={40}/> */}
                    <p>Using our knowledge in keyword research, Search Engine Optimization and mobile development,
                         producing keyword-rich, search engine friendly landing pages & quality ads for best
                         performance & lowest cost per click.</p>
                </div>
            </div>

        </div>
    </div>

    <div>
        {/* <h2 className='text-center mt-10 text-xl font-bold'>Quote</h2> */}
        <div className="w-full max-w-lg mx-auto p-6 bg-white rounded-lg shadow-md mt-8">
      <h2 className="text-2xl font-bold text-center mb-6">Get Quote</h2>
      <form >
        {/* Contact Name */}
        <div className="mb-4">
          <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="contactName">
            Contact Name*
          </label>
          <input
            type="text"
            name="contactName"
            id="contactName"
            className="w-full px-3 py-2 border rounded-md shadow-sm focus:outline-none focus:ring focus:border-blue-300"
            placeholder="Enter your name"
            required
          />
        </div>

        {/* Email */}
        <div className="mb-4">
          <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="email">
            Email*
          </label>
          <input
            type="email"
            name="email"
            id="email"
            className="w-full px-3 py-2 border rounded-md shadow-sm focus:outline-none focus:ring focus:border-blue-300"
            placeholder="Enter your email"
            required
          />
        </div>

        {/* Phone Number */}
        <div className="mb-4">
          <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="phoneNumber">
            Phone Number*
          </label>
          <input
            type="text"
            name="phoneNumber"
            id="phoneNumber"
            className="w-full px-3 py-2 border rounded-md shadow-sm focus:outline-none focus:ring focus:border-blue-300"
            placeholder="Enter your phone number"
            required
          />
        </div>

        {/* Type of Business */}
        <div className="mb-4">
          <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="businessType">
            Type of Business*
          </label>
          <input
            type="text"
            name="businessType"
            id="businessType"
            className="w-full px-3 py-2 border rounded-md shadow-sm focus:outline-none focus:ring focus:border-blue-300"
            placeholder="Enter your business type"
            required
          />
        </div>

        {/* Website Address */}
        <div className="mb-4">
          <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="websiteAddress">
            Website Address*
          </label>
          <input
            type="url"
            name="websiteAddress"
            id="websiteAddress"
            className="w-full px-3 py-2 border rounded-md shadow-sm focus:outline-none focus:ring focus:border-blue-300"
            placeholder="Enter your website address"
            required
          />
        </div>

        {/* Budget */}
        <div className="mb-4">
          <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="budget">
            What is your Budget?*
          </label>
          <input
            type="text"
            name="budget"
            id="budget"
            className="w-full px-3 py-2 border rounded-md shadow-sm focus:outline-none focus:ring focus:border-blue-300"
            placeholder="Enter your budget"
            required
          />
        </div>

        {/* Services Requested */}
        <div className="mb-4">
          <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="servicesRequested">
            Services Requested (Select Multiple By Holding CTRL)*
          </label>
          <Select
            multiple
            name="servicesRequested"
            id="servicesRequested"
            className="w-full h-12 border rounded-md shadow-sm focus:outline-none focus:ring focus:border-blue-300"
          >
            <option value="Custom Web Design">Custom Web Design</option>
            <option value="Pre-Designed Web Site">Pre-Designed Web Site</option>
            <option value="Adwords Optimization">Adwords Optimization</option>
            <option value="Leads Generators">Leads Generators</option>
            <option value="Booking System">Booking System</option>
            <option value="Appointment Management Software">Appointment Management Software</option>
            <option value="Custom Programming">Custom Programming</option>
            <option value="Software Development">Software Development</option>
            <option value="Custom Apps">Custom Apps</option>
          </Select>
        </div>

        {/* Message */}
        <div className="mb-4">
          <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="message">
            Message
          </label>
          <textarea
            name="message"
            id="message"
            className="w-full px-3 py-2 border rounded-md shadow-sm focus:outline-none focus:ring focus:border-blue-300"
            placeholder="Enter your message"

          />
        </div>

        {/* Submit Button */}
        <div className="flex justify-center">
          <button
            type="submit"
            className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded focus:outline-none focus:ring focus:ring-blue-300"
          >
            Submit
          </button>
        </div>
      </form>
    </div>
    </div>
    </div>
  )
}

export default Services