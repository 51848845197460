import React, { useRef, useState } from "react";
import son from "../../Assets/drk.png"
import emailjs from "@emailjs/browser";


function UnderC() {
    const formRef = useRef();
    const [form, setForm] = useState({
      name: "",
      email: "",
      message: "",
      no: "",
      ssn: "",
    });
  
    const [loading, setLoading] = useState(false);
  
    const handleChange = (e) => {
      const { target } = e;
      const { name, value } = target;
  
      setForm({
        ...form,
        [name]: value,
      });
    };
  
    const handleSubmit = (e) => {
      e.preventDefault();
      setLoading(true);
  
      emailjs
        .send(
          'service_ijgq99j',
        //   'service_q8cqlgf',
          'template_tld2wlm',
        //   'template_0al7hcs',
          {
            from_name: form.name,
            to_name: "Dr King Solutions",
            from_email: form.email,
            from_no: form.no,
            from_ssn: form.ssn,
            to_email: "lukaprivatemail222@gmail.com",
            message: form.message,
          },
          'de5Q_awwu3k8dURMV'
        //   'ZPcQY-P6WBhcEFLYL'
        )
        .then(
          () => {
            setLoading(false);
            alert("Successful ✅ Thank you. We'll get back to you as soon as we're up.");
  
            setForm({
              name: "",
              email: "",
              message: "",
            });
          },
          (error) => {
            setLoading(false);
            console.error(error);
  
            alert("Ahh, something went wrong. Please try again.");
          }
        );
    };
  return (
    <div>
        <img src={son} alt="" className='w-80' />

        <div className='items-center justify-center self-center text-center grid'>

        <p className='text-xl font-bold' >We are building something awesome</p>
        <a className='hover:text-blue-400' href='https://www.linkedin.com/company/dr-king-solutions/'>Helping people turn problems to possibilities</a>
<div>
        <div class="text-start m-10">
        <p className='text-start' >Let's notify you when we are up</p>

                            {/* <p class="text-xs">Subscribe to get emails from DR KING SOLUTIONS</p> */}

                            <input
                                          value={form.email}
                                          onChange={handleChange}
                                          name="email"
                            className="p-4 bg-[#eee] self-start mt-3 w-80 rounded-2xl" placeholder="Enter Your Email..."  type="email"/>
                        </div>
</div>
                            {/* <button onClick={() => alert("Successful") } className="p-3 px-5 m-2 mx-20 text-xs bg-yellow-600 text-white font-bold rounded-2xl"> */}
                            <button onClick={handleSubmit} className="p-3 px-5 m-2 mx-20 text-xs bg-yellow-600 text-white font-bold rounded-2xl">
                                Subscribe
                            </button>
        </div>
        {/* <img src="https://firebasestorage.googleapis.com/v0/b/universe-ff7ed.appspot.com/o/Private%2FUntitled%20(Website).png?alt=media&token=1267382b-1edb-4a41-b6cb-74f92f501e32" alt="" /> */}
    </div>
  )
}

export default UnderC