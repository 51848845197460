import React from 'react'
import img from "./marketing.png"
import { Select } from 'antd'

const Market = () => {
  return (
    <div>
        <div>
        {/* <h2 className='text-center m-2 p-5 text-3xl font-bold'> Digital Marketing</h2> */}
        <div className='text-center mb-20'>
            <img className='lg:w-[500px] mt-10 inline' src={img} alt="" />
        </div>

        <div className='lg:flex mx-5 justify-evenly'>   

            <div>
                <p className='text-start text-xl py-5 font-bold'>Smart Digital Marketing</p>
                <div className='lg:w-[500px] flex'>
                    {/* <TiTickOutline size={40}/> */}
                    <p>Transform your online presence with our innovative Smart Digital
                         Marketing solutions! Unlock targeted reach, amplify brand voice, and drive
                          conversions with our data-driven strategies. Stay ahead of the curve with AI-powered insights, expert guidance, 
                        and futuristic techniques to revolutionize your digital footprint and dominate the market!</p>
                </div>
            </div> 

        </div>
    </div>

    <div>
        {/* <h2 className='text-center mt-10 text-xl font-bold'>Contact us</h2> */}
        <div className="w-full max-w-lg mx-auto p-6 bg-white rounded-lg shadow-md mt-8">
      <h2 className="text-2xl font-bold text-center mb-6">Contact Us</h2>
      <form >
        {/* Contact Name */}
        <div className="mb-4">
          <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="contactName">
            Contact Name*
          </label>
          <input
            type="text"
            name="contactName"
            id="contactName"
            className="w-full px-3 py-2 border rounded-md shadow-sm focus:outline-none focus:ring focus:border-blue-300"
            placeholder="Enter your name"
            required
          />
        </div>

        {/* Email */}
        <div className="mb-4">
          <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="email">
            Email*
          </label>
          <input
            type="email"
            name="email"
            id="email"
            className="w-full px-3 py-2 border rounded-md shadow-sm focus:outline-none focus:ring focus:border-blue-300"
            placeholder="Enter your email"
            required
          />
        </div>

        {/* Phone Number */}
        <div className="mb-4">
          <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="phoneNumber">
            Phone Number*
          </label>
          <input
            type="text"
            name="phoneNumber"
            id="phoneNumber"
            className="w-full px-3 py-2 border rounded-md shadow-sm focus:outline-none focus:ring focus:border-blue-300"
            placeholder="Enter your phone number"
            required
          />
        </div>

        {/* Type of Business */}
        <div className="mb-4">
          <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="businessType">
            Type of Business*
          </label>
          <input
            type="text"
            name="businessType"
            id="businessType"
            className="w-full px-3 py-2 border rounded-md shadow-sm focus:outline-none focus:ring focus:border-blue-300"
            placeholder="Enter your business type"
            required
          />
        </div>

        {/* Website Address */}
        <div className="mb-4">
          <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="websiteAddress">
            Website Address*
          </label>
          <input
            type="url"
            name="websiteAddress"
            id="websiteAddress"
            className="w-full px-3 py-2 border rounded-md shadow-sm focus:outline-none focus:ring focus:border-blue-300"
            placeholder="Enter your website address"
            required
          />
        </div>

        {/* Budget */}
        <div className="mb-4">
          <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="budget">
            What is your Budget?*
          </label>
          <input
            type="text"
            name="budget"
            id="budget"
            className="w-full px-3 py-2 border rounded-md shadow-sm focus:outline-none focus:ring focus:border-blue-300"
            placeholder="Enter your budget"
            required
          />
        </div>

        {/* Services Requested */}
        <div className="mb-4">
          <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="servicesRequested">
            Services Requested (Select Multiple By Holding CTRL)*
          </label>
          <Select
            multiple
            name="servicesRequested"
            id="servicesRequested"
            className="w-full h-12 border rounded-md shadow-sm focus:outline-none focus:ring focus:border-blue-300"
          >
            <option value="Custom Web Design">Custom Web Design</option>
            <option value="Pre-Designed Web Site">Pre-Designed Web Site</option>
            <option value="Adwords Optimization">Adwords Optimization</option>
            <option value="Leads Generators">Leads Generators</option>
            <option value="Booking System">Booking System</option>
            <option value="Appointment Management Software">Appointment Management Software</option>
            <option value="Custom Programming">Custom Programming</option>
            <option value="Software Development">Software Development</option>
            <option value="Custom Apps">Custom Apps</option>
          </Select>
        </div>

        {/* Message */}
        <div className="mb-4">
          <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="message">
            Message
          </label>
          <textarea
            name="message"
            id="message"
            className="w-full px-3 py-2 border rounded-md shadow-sm focus:outline-none focus:ring focus:border-blue-300"
            placeholder="Enter your message"

          />
        </div>

        {/* Submit Button */}
        <div className="flex justify-center">
          <button
            type="submit"
            className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded focus:outline-none focus:ring focus:ring-blue-300"
          >
            Submit
          </button>
        </div>
      </form>
    </div>
    </div>
    </div>
  )
}

export default Market