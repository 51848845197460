import React from 'react'
import dd from "./ACTIVE VIBES.jpg"
import ww from "./Active Flex 2.jpg"
import pp from "./DINNER fly.jpg"

const Graphics = () => {
  return (
    <div>
    <div>
    <h2 className='text-center m-2 p-5 text-3xl font-bold'>ALL GRAPHICS DESIGN SERVICES</h2>
    <div className='lg:flex justify-evenly my-10 p-10'>
        <div>
            <img className='w-96 hover:scale-110 duration-700 p-5' src={dd} alt="" />
        </div>
        <div>
            <img className='w-96 hover:scale-110 duration-700 p-2' src={ww} alt="" />
        </div>
        <div>
            <img className='w-96 hover:scale-110 duration-700 p-5' src={pp} alt="" />
        </div>
    </div>

    <div className='lg:flex mx-5 justify-evenly'>   

        <div>
            <p className='text-start text-xl py-5 font-bold'>FULLY CUSTOMDESIGNS</p>
            <div className='w-96 flex'>
                {/* <TiTickOutline size={40}/> */}
                <p>Graphix Image ensures the perfect solution, so that you and your business can thrive with all the opportunities 
                for promotion and business on the Internet by providing you with an innovative professional web site.</p>
            </div>
        </div>
        <div>
            <p className='text-start text-xl py-5 font-bold'>LEADSGENERATORS</p>
            <div className='w-96 flex'>
                {/* <TiTickOutline className=' w-96'/> */}
                <p>Graphix Image ensures the perfect solution, so that you and your business can thrive with all the opportunities 
                for promotion and business on the Internet by providing you with an innovative professional web site.</p>
            </div>
        </div>
        <div>
            <p className='text-start text-xl py-5 font-bold'>ADWORDSMARKETING</p>
            <div className='w-96 flex'>
                {/* <TiTickOutline size={40}/> */}
                <p>Graphix Image ensures the perfect solution, so that you and your business can thrive with all the opportunities 
                for promotion and business on the Internet by providing you with an innovative professional web site.</p>
            </div>
        </div>

    </div>
</div>
    </div>
  )
}

export default Graphics