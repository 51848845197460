import React from "react";
import CarPng from "../../Assets/rolls.png";
import digital from "./digi.jpg"

const About = () => {
  return (
    <div id="cars" className="dark:bg-dark bg-slate-100 sm:min-h-[600px] sm:grid sm:place-items-center duration-300">
      <div className="container">
        <div className="grid grid-cols-1 sm:grid-cols-2 place-items-center">
          <div data-aos="slide-right" data-aos-duration="1500" className="pt-5">
            <img
              src={"https://www.recruitmymom.co.za/sites/recruitmymom/files/blog/What%20To%20Look%20For%20When%20Hiring%20An%20Excellent%20Personal%20Assistant.jpg"}
              alt=""
              className="sm:scale-125 sm:-translate-x-11 max-h-[300px] drop-shadow-[2px_10px_6px_rgba(0,0,0,0.50)]"
            />
          </div>
          <div>
            <div className="space-y-5 sm:p-16 pb-6">
              <h1
                data-aos="fade-up"
                className="text-3xl lg:text-4xl my-10 font-bold font-Ubuntu"
              >
                About Dr King Solutions
              </h1>
              <p data-aos="fade-up" className="leading-8 tracking-wide font-Ubuntu">
              DR KING SOLUTIONS, DR. ARIEL ROSITA KING, MPH, MBA, PHD (UK), DTM&H, PHD (FRANCE) 
              Founder and President Ariel Foundation International & Dr. King Solutions "Empowerment is not 
              about giving power, but about releasing the power they already have." This quote embodies the spirit 
              of Dr. Ariel King's work in fostering empowerment and creating opportunities for individuals and communities
               to thrive. Dr. King's dedication to empowering children and youth, fostering their active involvement in 
               international decision-making processes, has garnered widespread recognition and acclaim.
                {/* Our culture is quality luxury transportation services,
                 with commitment to meeting the individual needs of each
                  customer has propelled MARINA GLOBAL TRANSPORTATION as the #1 limousine company in GA,
                   Unique fleet of luxurious limousines, 
                   professional chauffeurs, red carpet VIP limo services etc. */}
                              </p>

                              <p data-aos="fade-up font-Ubuntu" className="pb-10">
                              As the Founder and President of Ariel Foundation International (www.arielfoundation.org ), a non-profit organization founded in 2002,
               holding special consultative ECOSOC Status with the United Nations (2016) and NGO Status at the European Parliament (2015).
               
              </p>
              {/* <p data-aos="fade-up font-Ubuntu" className="pb-10">
              Registered in the USA, France, Switzerland, and Ghana. Dr. King has made a
               significant impact on the lives of youth across 30+ countries. With a focus on leadership, 
               entrepreneurship, and community service, AFI has impacted approximately 150,000 young individuals.
              </p> */}
              {/* <p data-aos="fade-up font-Ubuntu">
              With just one phone call, experience all the fun and excitement
              </p> */}
              <a href="tel:800-556-1849" data-aos="fade-up" className="bg-black text-white px-10 py-3 rounded-full font-Ubuntu">
                Call Now
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
