import React from 'react';
import sf from "./bnbg.png";
import rsp from "../tripsccreens/Img/site.png";
import { FaLink, FaPhone } from 'react-icons/fa6';
import mn from "./img/ppScreenshot 2024-09-24 144105.png"
import Et from "./img/EEScreenshot 2024-09-24 145657.png"
import Em from "./img/Screenshot 2024-09-24 145941.png"
import { MdOutlineAttachEmail } from 'react-icons/md';


const Estate = () => {
  const handleClick = (url) => {
    window.open(url, '_blank');  // Open the link in a new tab
  };

  return (
    <div className='text-center flex-1 justify-center'>
      <h2 className='text-center m-2 p-5 text-3xl font-bold'>WEBSITES</h2>

      <div className='grid lg:flex-cols-2 items-center self-center justify-center '>
      

    <h2 className='text-center m-2 p-5 text-3xl'>REAL ESTATE WEBSITES</h2>

        <div className='relative lg:w- lg:p-10 mb-10 hover:scale-105 duration-700'>
          <img className='lg:w-[800px] lg:h-[] transition-all duration-300 filter hover:blur-md' src={sf} alt="" />
          <div 
            className='absolute inset-0 flex items-center justify-center opacity-0 hover:opacity-100 transition-opacity duration-300'
           
          >
             <div className='flex gap-5'>
            <div className='bg-white p-3 flex gap-5 rounded-full shadow-lg cursor-pointer'>
              <FaLink  onClick={() => handleClick('https://real-estate-website-eight-rouge.vercel.app/')} color='black' />
            </div>
            <div className='bg-white p-3 flex gap-5 rounded-full shadow-lg cursor-pointer'>
            <a href="tel:800-556-1849" >
              <FaPhone color='black' />
              </a>
            </div>
            <div className='bg-white p-3 flex gap-5 rounded-full shadow-lg cursor-pointer'>
            <a href="mailto:info@lastminutedispatch.com" >
              <MdOutlineAttachEmail color='black' />
              </a>
            </div>
          </div>
          </div>
        </div>

        <div className='relative lg:w- lg:p-10 mb-10 hover:scale-105 duration-700'>
          <img className='lg:w-[800px] lg:h-[] transition-all duration-300 filter hover:blur-md' src={rsp} alt="" />
          <div 
            className='absolute inset-0 flex items-center justify-center opacity-0 hover:opacity-100 transition-opacity duration-300'
          >
             <div className='flex gap-5'>
            <div className='bg-white p-3 flex gap-5 rounded-full shadow-lg cursor-pointer'>
              <FaLink  onClick={() => handleClick('https://real-estate-website-eight-rouge.vercel.app/')} color='black' />
            </div>
            <div className='bg-white p-3 flex gap-5 rounded-full shadow-lg cursor-pointer'>
            <a href="tel:800-556-1849" >
              <FaPhone color='black' />
              </a>
            </div>
            <div className='bg-white p-3 flex gap-5 rounded-full shadow-lg cursor-pointer'>
            <a href="mailto:info@lastminutedispatch.com" >
              <MdOutlineAttachEmail color='black' />
              </a>
            </div>
          </div>
          </div>
        </div>


        <div className='relative lg:w- lg:p-10 mb-10 hover:scale-105 duration-700'>
          <img className='lg:w-[800px] lg:h-[] transition-all duration-300 filter hover:blur-md' src={mn} alt="" />
          <div 
            className='absolute inset-0 flex items-center justify-center opacity-0 hover:opacity-100 transition-opacity duration-300'
          >
             <div className='flex gap-5'>
            <div className='bg-white p-3 flex gap-5 rounded-full shadow-lg cursor-pointer'>
              <FaLink  onClick={() => handleClick('http://bankconsultingservices.com/')} color='black' />
            </div>
            <div className='bg-white p-3 flex gap-5 rounded-full shadow-lg cursor-pointer'>
            <a href="tel:800-556-1849" >
              <FaPhone color='black' />
              </a>
            </div>
            <div className='bg-white p-3 flex gap-5 rounded-full shadow-lg cursor-pointer'>
            <a href="mailto:info@lastminutedispatch.com" >
              <MdOutlineAttachEmail color='black' />
              </a>
            </div>
          </div>
          </div>
        </div>

        <div className='relative lg:w- lg:p-10 mb-10 hover:scale-105 duration-700'>
          <img className='lg:w-[800px] lg:h-[] transition-all duration-300 filter hover:blur-md' src={Et} alt="" />
          <div 
            className='absolute inset-0 flex items-center justify-center opacity-0 hover:opacity-100 transition-opacity duration-300'
          >
             <div className='flex gap-5'>
            <div className='bg-white p-3 flex gap-5 rounded-full shadow-lg cursor-pointer'>
              <FaLink  onClick={() => handleClick('http://novainsuranceservices.net/')} color='black' />
            </div>
            <div className='bg-white p-3 flex gap-5 rounded-full shadow-lg cursor-pointer'>
            <a href="tel:800-556-1849" >
              <FaPhone color='black' />
              </a>
            </div>
            <div className='bg-white p-3 flex gap-5 rounded-full shadow-lg cursor-pointer'>
            <a href="mailto:info@lastminutedispatch.com" >
              <MdOutlineAttachEmail color='black' />
              </a>
            </div>
          </div>
          </div>
        </div>

        <div className='relative lg:w- lg:p-10 mb-10 hover:scale-105 duration-700'>
          <img className='lg:w-[800px] lg:h-[] transition-all duration-300 filter hover:blur-md' src={Em} alt="" />
          <div 
            className='absolute inset-0 flex items-center justify-center opacity-0 hover:opacity-100 transition-opacity duration-300'
          >
             <div className='flex gap-5'>
            <div className='bg-white p-3 flex gap-5 rounded-full shadow-lg cursor-pointer'>
              <FaLink  onClick={() => handleClick('https://graphiximage.com/samples/lawyers-web-design/')} color='black' />
            </div>
            <div className='bg-white p-3 flex gap-5 rounded-full shadow-lg cursor-pointer'>
            <a href="tel:800-556-1849" >
              <FaPhone color='black' />
              </a>
            </div>
            <div className='bg-white p-3 flex gap-5 rounded-full shadow-lg cursor-pointer'>
            <a href="mailto:info@lastminutedispatch.com" >
              <MdOutlineAttachEmail color='black' />
              </a>
            </div>
          </div>
          </div>
        </div>

       

 
      </div>
    </div>
  );
};

export default Estate;
