import React from 'react';
import sts from "./truck.png";
import { FaLink, FaPhone } from 'react-icons/fa6';
import mn from "./img/ppScreenshot 2024-09-24 143935.png"
import { MdOutlineAttachEmail } from 'react-icons/md';
import mb from "./img/ppScreenshot 2024-09-24 142944.png"
import mv from "./img/Screenshot 2024-09-24 093621.png"
import mq from "./img/Screenshot 2024-09-24 093740.png"
import rr from "./img/citycar.png"
import asu from "./img/Rumble.png"
import me from "./img/rental.png"
import et from "../../Assets/website/Screenshot 2024-10-11 135152.png"
import us from "./img/joker.png"

const Ride = () => {
  const handleClick = (url) => {
    window.open(url, '_blank');  // Open the link in a new tab
  };

  return (
    <div className='text-center flex-1 justify-center'>
      <h2 className='text-center m-2 p-5 text-3xl font-bold'>WEBSITES</h2>

      <div className='grid lg:flex-cols-2 items-center self-center justify-center '>

        <h2 className='text-center m-2 p-5 text-3xl'>DISPATCH WEBSITES</h2>


        <div className='relative lg:w- lg:p-10 mb-10 text-center items-center hover:scale-105 duration-700'>
        <img className='lg:w-[800px] lg:h-[] transition-all duration-300 filter hover:blur-md' src={mn} alt="" />
     
        <div 
          className='absolute inset-0 flex items-center justify-center opacity-0 hover:opacity-100 transition-opacity duration-300'
         >
        <div className='flex gap-5'>
          <div className='bg-white p-3 flex gap-5 rounded-full shadow-lg cursor-pointer'>
            <FaLink  onClick={() => handleClick('https://sabalimo.com/')} color='black' />
          </div>
          <div className='bg-white p-3 flex gap-5 rounded-full shadow-lg cursor-pointer'>
          <a href="tel:800-556-1849" >
            <FaPhone color='black' />
            </a>
          </div>
          <div className='bg-white p-3 flex gap-5 rounded-full shadow-lg cursor-pointer'>
          <a href="mailto:info@lastminutedispatch.com" >
            <MdOutlineAttachEmail color='black' />
            </a>
          </div>
        </div>

        </div>
      </div>


        <div className='relative lg:w- lg:p-10 mb-10 text-center items-center hover:scale-105 duration-700'>
        <img className='lg:w-[800px] lg:h-[] transition-all duration-300 filter hover:blur-md' src={mb} alt="" />
     
        <div 
          className='absolute inset-0 flex items-center justify-center opacity-0 hover:opacity-100 transition-opacity duration-300'
         >
        <div className='flex gap-5'>
          <div className='bg-white p-3 flex gap-5 rounded-full shadow-lg cursor-pointer'>
            <FaLink  onClick={() => handleClick('https://eagleeyelimo.com/')} color='black' />
          </div>
          <div className='bg-white p-3 flex gap-5 rounded-full shadow-lg cursor-pointer'>
          <a href="tel:800-556-1849" >
            <FaPhone color='black' />
            </a>
          </div>
          <div className='bg-white p-3 flex gap-5 rounded-full shadow-lg cursor-pointer'>
          <a href="mailto:info@lastminutedispatch.com" >
            <MdOutlineAttachEmail color='black' />
            </a>
          </div>
        </div>

        </div>
      </div>

        <div className='relative lg:w- lg:p-10 mb-10 text-center items-center hover:scale-105 duration-700'>
        <img className='lg:w-[800px] lg:h-[] transition-all duration-300 filter hover:blur-md' src={et} alt="" />
     
        <div 
          className='absolute inset-0 flex items-center justify-center opacity-0 hover:opacity-100 transition-opacity duration-300'
         >
        <div className='flex gap-5'>
          <div className='bg-white p-3 flex gap-5 rounded-full shadow-lg cursor-pointer'>
            <FaLink  onClick={() => handleClick('https://skylarklimo.com/')} color='black' />
          </div>
          <div className='bg-white p-3 flex gap-5 rounded-full shadow-lg cursor-pointer'>
          <a href="tel:800-556-1849" >
            <FaPhone color='black' />
            </a>
          </div>
          <div className='bg-white p-3 flex gap-5 rounded-full shadow-lg cursor-pointer'>
          <a href="mailto:info@lastminutedispatch.com" >
            <MdOutlineAttachEmail color='black' />
            </a>
          </div>
        </div>

        </div>
      </div>


        <div className='relative lg:w- lg:p-10 mb-10 text-center items-center hover:scale-105 duration-700'>
          <img className='lg:w-[800px] lg:h-[] transition-all duration-300 filter hover:blur-md' src={sts} alt="" />
       
          <div 
            className='absolute inset-0 flex items-center justify-center opacity-0 hover:opacity-100 transition-opacity duration-300'
           >
          <div className='flex gap-5'>
            <div className='bg-white p-3 flex gap-5 rounded-full shadow-lg cursor-pointer'>
              <FaLink  onClick={() => handleClick('https://transportio-master.vercel.app')} color='black' />
            </div>
            <div className='bg-white p-3 flex gap-5 rounded-full shadow-lg cursor-pointer'>
            <a href="tel:800-556-1849" >
              <FaPhone color='black' />
              </a>
            </div>
            <div className='bg-white p-3 flex gap-5 rounded-full shadow-lg cursor-pointer'>
            <a href="mailto:info@lastminutedispatch.com" >
              <MdOutlineAttachEmail color='black' />
              </a>
            </div>
          </div>

          </div>
        </div>

        <div className='relative lg:w- lg:p-10 mb-10 text-center items-center hover:scale-105 duration-700'>
          <img className='lg:w-[800px] lg:h-[] transition-all duration-300 filter hover:blur-md' src={mv} alt="" />
       
          <div 
            className='absolute inset-0 flex items-center justify-center opacity-0 hover:opacity-100 transition-opacity duration-300'
           >
          <div className='flex gap-5'>
            <div className='bg-white p-3 flex gap-5 rounded-full shadow-lg cursor-pointer'>
              <FaLink  onClick={() => handleClick('https://rental-x-20-07-24-main.vercel.app/')} color='black' />
            </div>
            <div className='bg-white p-3 flex gap-5 rounded-full shadow-lg cursor-pointer'>
            <a href="tel:800-556-1849" >
              <FaPhone color='black' />
              </a>
            </div>
            <div className='bg-white p-3 flex gap-5 rounded-full shadow-lg cursor-pointer'>
            <a href="mailto:info@lastminutedispatch.com" >
              <MdOutlineAttachEmail color='black' />
              </a>
            </div>
          </div>

          </div>
        </div>

        <div className='relative lg:w- lg:p-10 mb-10 text-center items-center hover:scale-105 duration-700'>
        <img className='lg:w-[800px] lg:h-[] transition-all duration-300 filter hover:blur-md' src={mq} alt="" />
     
        <div 
          className='absolute inset-0 flex items-center justify-center opacity-0 hover:opacity-100 transition-opacity duration-300'
         >
        <div className='flex gap-5'>
          <div className='bg-white p-3 flex gap-5 rounded-full shadow-lg cursor-pointer'>
            <FaLink  onClick={() => handleClick('https://rental-x-20-07-24-main.vercel.app/')} color='black' />
          </div>
          <div className='bg-white p-3 flex gap-5 rounded-full shadow-lg cursor-pointer'>
          <a href="tel:800-556-1849" >
            <FaPhone color='black' />
            </a>
          </div>
          <div className='bg-white p-3 flex gap-5 rounded-full shadow-lg cursor-pointer'>
          <a href="mailto:info@lastminutedispatch.com" >
            <MdOutlineAttachEmail color='black' />
            </a>
          </div>
        </div>

        </div>
      </div>
 
 

      <div className='relative lg:w- lg:p-10 mb-10 text-center items-center hover:scale-105 duration-700'>
      <img className='lg:w-[800px] lg:h-[] transition-all duration-300 filter hover:blur-md' src={rr} alt="" />
   
      <div 
        className='absolute inset-0 flex items-center justify-center opacity-0 hover:opacity-100 transition-opacity duration-300'
       >
      <div className='flex gap-5'>
        <div className='bg-white p-3 flex gap-5 rounded-full shadow-lg cursor-pointer'>
          <FaLink  onClick={() => handleClick('https://rental-x-20-07-24-main.vercel.app/')} color='black' />
        </div>
        <div className='bg-white p-3 flex gap-5 rounded-full shadow-lg cursor-pointer'>
        <a href="tel:800-556-1849" >
          <FaPhone color='black' />
          </a>
        </div>
        <div className='bg-white p-3 flex gap-5 rounded-full shadow-lg cursor-pointer'>
        <a href="mailto:info@lastminutedispatch.com" >
          <MdOutlineAttachEmail color='black' />
          </a>
        </div>
      </div>

      </div>
    </div>



    <div className='relative lg:w- lg:p-10 mb-10 text-center items-center hover:scale-105 duration-700'>
    <img className='lg:w-[800px] lg:h-[] transition-all duration-300 filter hover:blur-md' src={asu} alt="" />
 
    <div 
      className='absolute inset-0 flex items-center justify-center opacity-0 hover:opacity-100 transition-opacity duration-300'
     >
    <div className='flex gap-5'>
      <div className='bg-white p-3 flex gap-5 rounded-full shadow-lg cursor-pointer'>
        <FaLink  onClick={() => handleClick('https://rental-x-20-07-24-main.vercel.app/')} color='black' />
      </div>
      <div className='bg-white p-3 flex gap-5 rounded-full shadow-lg cursor-pointer'>
      <a href="tel:800-556-1849" >
        <FaPhone color='black' />
        </a>
      </div>
      <div className='bg-white p-3 flex gap-5 rounded-full shadow-lg cursor-pointer'>
      <a href="mailto:info@lastminutedispatch.com" >
        <MdOutlineAttachEmail color='black' />
        </a>
      </div>
    </div>

    </div>
  </div>



  <div className='relative lg:w- lg:p-10 mb-10 text-center items-center hover:scale-105 duration-700'>
  <img className='lg:w-[800px] lg:h-[] transition-all duration-300 filter hover:blur-md' src={me} alt="" />

  <div 
    className='absolute inset-0 flex items-center justify-center opacity-0 hover:opacity-100 transition-opacity duration-300'
   >
  <div className='flex gap-5'>
    <div className='bg-white p-3 flex gap-5 rounded-full shadow-lg cursor-pointer'>
      <FaLink  onClick={() => handleClick('https://limoscarrental.vercel.app/')} color='black' />
    </div>
    <div className='bg-white p-3 flex gap-5 rounded-full shadow-lg cursor-pointer'>
    <a href="tel:800-556-1849" >
      <FaPhone color='black' />
      </a>
    </div>
    <div className='bg-white p-3 flex gap-5 rounded-full shadow-lg cursor-pointer'>
    <a href="mailto:info@lastminutedispatch.com" >
      <MdOutlineAttachEmail color='black' />
      </a>
    </div>
  </div>

  </div>
</div>


  <div className='relative lg:w- lg:p-10 mb-10 text-center items-center hover:scale-105 duration-700'>
  <img className='lg:w-[800px] lg:h-[] transition-all duration-300 filter hover:blur-md' src={us} alt="" />

  <div 
    className='absolute inset-0 flex items-center justify-center opacity-0 hover:opacity-100 transition-opacity duration-300'
  >
  <div className='flex gap-5'>
    <div className='bg-white p-3 flex gap-5 rounded-full shadow-lg cursor-pointer'>
      <FaLink  onClick={() => handleClick('https://limosanime.vercel.app/')} color='black' />
    </div>
    <div className='bg-white p-3 flex gap-5 rounded-full shadow-lg cursor-pointer'>
    <a href="tel:800-556-1849" >
      <FaPhone color='black' />
      </a>
    </div>
    <div className='bg-white p-3 flex gap-5 rounded-full shadow-lg cursor-pointer'>
    <a href="mailto:info@lastminutedispatch.com" >
      <MdOutlineAttachEmail color='black' />
      </a>
    </div>
  </div>

  </div>
  </div>

      </div>
    </div>
  );
};

export default Ride;
